import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import "./TechnologyModal.css";
import InputField from "./InputFields/InputField";
import OtpInput from "react-otp-input";
import { NotificationManager } from "react-notifications";
// import cookie from "react-cookie";

export default function TechnologyModal(props) {
  const [fieldValue, setFieldValue] = useState({
    company: "",
    name: "",
    mobile: "",
    email: "",
    role: "",
    leadType: "API",
  });

  console.log(fieldValue, "reetyeddddddddddddddddddddddddddddddddddddddd");
  const [clickCount, setClickCount] = useState(0);
  const [OTPBtnDisabled, setOTPBtnDisabled] = useState(true);

  const [role, setRole] = useState([]);
  const [validations, setValidations] = useState({
    company: "",
    name: "",
    mobile: "",
    email: "",
  });

  const [otpView, setOtpView] = useState(false);
  const [OTP, setOTP] = useState();
  const handleChange = (OTP) => setOTP(OTP);

  const otpVal = { mobile: fieldValue.mobile, otp: OTP };

  const validateAll = () => {
    const { company, name, email, mobile, role } = fieldValue;
    const validations = {
      company: "",
      name: "",
      email: "",
      mobile: "",
      role: "",
    };
    let isValid = true;

    if (!company) {
      validations.company = "Company Name is required";
      isValid = false;
    }

    if (!name) {
      validations.name = " Name is required";
      isValid = false;
    }

    if (!role) {
      validations.role = "Please select the role";
      isValid = false;
    }
    // if (name && name.length < 3 || name.length > 50) {
    //   validations.name = 'Name must contain between 3 and 50 characters'
    //   isValid = false
    // }

    if (!email) {
      validations.email = "Email is required";
      isValid = false;
    }

    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = "Email format must be as example@mail.com";
      isValid = false;
    }

    if (!mobile) {
      validations.mobile = "Phone number is required";
      isValid = false;
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const validateOne = (e) => {
    const { name } = e.target;
    const value = fieldValue[name];
    let message = "";

    // if (!value) {
    //   message = `${name} is required`;
    // }
    if (!value && name == "company") {
      message = `Company name is required`;
    }
    if (!value && name == "name") {
      message = `Name is required`;
    }
    if (!value && name == "mobile") {
      message = `Phone number is required`;
    }
    if (!value && name == "role") {
      message = `Please select the role`;
    }

    if (value && name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      message = "Please enter a valid email";
    }

    setValidations({ ...validations, [name]: message });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFieldValue((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };
  function handleSelectChange(event) {
    setRole(event.target.value);
    console.log(role);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateAll();

    if (!isValid) {
      return false;
    }
    timerFunction();

    const fieldValueStringify = JSON.stringify(fieldValue);
    console.log("fieldValueStringify initial", fieldValueStringify);
    fetch(`${window.appConfig.getOtp}`, {
      method: "POST",
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
      },
      body: fieldValueStringify,
    })
      .then((res) => res.text())

      .then((result) => {
        if (result === "Email Already Registered.") {
          window.location.replace(props.href);
        }else if(result === "Mobile Already Registered") {
          window.location.replace(props.href);
        }
        else {
          setOtpView(true);
          return NotificationManager.success(result);
          
        }
      })
      .catch((err) => console.log("err", err));

   
  };
  const otpSubmitHandler = (e) => {
    e.preventDefault();

    const fieldValueStringifyOTP = JSON.stringify(otpVal);
    console.log(
      fieldValueStringifyOTP,
      "fieldValueStringify fieldValueStringify"
    );
    fetch(
      // `http://35.92.73.23:3333/validateOtp`,
      `${window.appConfig.validateOtp}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: fieldValueStringifyOTP,
      }
    )
      .then((res) => res.text())
      .then((result) => {
        if (result === "Lead Created") {
          window.location.replace(props.href);
        } else {
          return NotificationManager.success(result);
        }
      })
      .catch((err) => console.log("err", err));
  };

  // Resend OTP

  const mobileNumber = fieldValue.mobile;

  const resendOTP = (e) => {
    e.preventDefault();
    timerFunction();
    fetch(`${window.appConfig.resendOTP}/${mobileNumber}`, {
      method: "POST",
      headers: {
        Accept: "text/plain",
      },
    })
      .then((res) => res.text())
      .then((result) => {
        if (result === "Lead Created") {
          window.location.replace(props.href);
        } else {
          return NotificationManager.success(result);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const timerFunction = () => {
    console.log("time start", clickCount);
    if (clickCount < 2) {
      const resendOTPTimer = setTimeout(() => {
        setOTPBtnDisabled(false);
      }, 540000);

      return () => {
        clearTimeout(resendOTPTimer);
      };
    }
  };
  return (
    <div className="technologyModal">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="form-header">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="form-title mb-0 me-5">
              {!otpView
                ? "Please Fill the Form to View APIs"
                : "Please Enter OTP"}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!otpView ? (
            <form onSubmit={handleSubmit}>
              <div className="scrollableView">
                <div className="input-container mb-2">
                  <label className="d-block mb-1">Company Name*</label>
                  <InputField
                    type="text"
                    name="company"
                    value={fieldValue.company}
                    onChange={handleInputChange}
                    onBlur={validateOne}
                    placeholder="Please enter company name"
                    required="true"
                  />
                  <span className="d-block">{validations.company}</span>
                </div>

                <div className="input-container mb-2">
                  <label className="d-block mb-1"> Name*</label>
                  <InputField
                    type="text"
                    name="name"
                    value={fieldValue.name}
                    onChange={handleInputChange}
                    onBlur={validateOne}
                    required="true"
                    placeholder="Please enter your name"
                  />
                  <span className="d-block">{validations.name}</span>
                </div>

                <div className="input-container mb-2">
                  <label className="mb-1 d-block"> Phone Number*</label>
                  <InputField
                    type="mobile"
                    name="mobile"
                    value={fieldValue.mobile}
                    onChange={handleInputChange}
                    onBlur={validateOne}
                    required="true"
                    placeholder="Please enter mobile"
                  />
                  <span className="d-block">{validations.mobile}</span>
                </div>
                <div className="input-container mb-2">
                  <label className="mb-1 d-block">Email*</label>
                  <InputField
                    type="email"
                    name="email"
                    value={fieldValue.email}
                    onChange={handleInputChange}
                    onBlur={validateOne}
                    required="true"
                    placeholder="Please enter email"
                  />
                  <span className="d-block">{validations.email}</span>
                </div>
                <div className="input-container mb-3">
                  <label className="mb-1 d-block">Role*</label>
                  <select
                    name="role"
                    value={fieldValue.role}
                    onChange={handleInputChange}
                    onBlur={validateOne}
                  >
                    <option value="" disabled selected hidden>
                      Role
                    </option>

                    <option value="CTO">CTO</option>
                    <option value="Director">Director</option>
                    <option selected value="Technical Manager">
                      Technical Manager
                    </option>
                    <option value="Architect">Architect</option>
                    <option value="Others">Others</option>
                  </select>
                  {validations.role && (
                    <span className="d-block">{validations.role}</span>
                  )}
                </div>
              </div>

              <button className="Formbutton" type="submit">
                Submit
              </button>
            </form>
          ) : (
            <form onSubmit={otpSubmitHandler} className="pe-5">
              <div className="input-container-mod">
                {/* <h2 className="container-heading2 mb-2"> Please enter OTP</h2> */}
                <div className="otp-container mb-2 ">
                  <OtpInput
                    value={OTP}
                    numInputs={6}
                    separator={<span>-</span>}
                    onChange={handleChange}
                    isInputNum={true}
                    inputStyle={{
                      border: "1px solid #000",
                      borderRadius: "8px",
                      width: "40px",
                      height: "40px",
                      fontSize: "12px",
                      color: "#000",
                      fontWeight: "400",
                      caretColor: "#000",
                    }}
                    focusStyle={{
                      outline: "none",
                    }}
                  />
                </div>
                <p className="mb-4 resend-otp">
                  Didn't Received OTP ?{" "}
                  <span
                    className={`${
                      OTPBtnDisabled ? "disabled-link" : "cursor-pointer "
                    } `}
                    onClick={resendOTP}
                  >
                    Resend OTP
                  </span>
                </p>
                <button className="Formbutton">Submit and View API</button>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
