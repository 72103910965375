import React from "react";
import "./SecondaryNavbar.css";
import { NavLink } from "react-router-dom";

export default function SecondaryNavbar({ props, props2 }) {
  return (
    <div className="Secondary-navbar d-flex justify-content-between">
   <div>
        {props.map((e) => {
          return <NavLink to={e.to} className={`${e.disable} ? "disabled-link" : ""}`}>{e.link}</NavLink>;
        })}
        </div>

        {props2 && <div>
        {props2.map((e) => {
          return <NavLink to={e.to} className={`${e.disable} ? "disabled-link" : ""}`}>{e.link}</NavLink>;
        })}
        </div>}
      </div>
  );
}
