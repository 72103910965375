import React from "react";
import Icon1 from "../../images/app2.png";
import Icon2 from "../../images/app6.png";
import Icon3 from "../../images/app4.png";
import Icon4 from "../../images/market-positioning.png";
import Icon5 from "../../images/socialmarket.svg";
import Icon6 from "../../images/retail.svg";

import { Row, Col } from "react-bootstrap";
import { Container, Button } from "@mui/material";
import TickMark from "../../images/tick.png";

export default function ExploreTechnologySection2() {
  const feature1 = [
"    Easy to monitor & tracking of agri assets and farms",
"    Quality assurance agri assets and farms",    
"    Monthly and invoice will be generated forte agri assets",
"    Only verified assets will be delivered"  ];

const feature2 =[
  "Manage the community members sevices",
"Contact the organisation if any emergency needed",
"Can become a community member to manage their assets"
]
const feature3 = [
  "Farm onboarding Management: Create and manage farms efficiently",
  "Offline Registration: requesting for creating users and manage these users.",
];
  const feature4 = [
    "onMarketplace provides a wide array of organic products, including fresh produce and processed items, offering consumers a diverse selection of organic choices.",
    "Detailed information about each product, including its source, farming methods, certifications, and nutritional facts, helps customers make informed choices.",
    "The products are guaranteed to be authentic as they meet strict organic certification standards.",
  ];

  const feature5 = [
    "Users can connect with a wide range of businesses, ask questions, and gather information about products or services.",
    "Facilitates networking and collaboration among businesses, allowing them to exchange insights and potentially form partnerships.",
    "Businesses can showcase their offerings, complete with detailed descriptions, images, and pricing."
  ];

  const feature6 = [
    "Access a diverse range of agri-business and agri-asset investment opportunities.",
    "Gain access to comprehensive information and data about agri-businesses and assets to make well-informed investment choices.",
  ];
  const appSectionData = [
    {
      id: "DTNApp",
      href: "https://play.google.com/store/apps/details?id=com.gbrapp.aamcapp",
      title: "DTN App",
      description:"AAMC  is an management application which is used to manage of all the agri assets and the farms in the network.",
      image: Icon3,
      features: feature1,
    },
    // {
    //   id: "ABNApp",
    //   href: "http://abn.gbrfarming.com/auth/3/signup",
    //   title: "ABN App",
    //   description:"ABN is Digital Transformation Network for MSMEs to maximize the profit. It is provided to MSMEs as online service (SaaS) to build Local community, Production network and Global network.",
    //   image: Icon1,
    //   features: feature2,
    //   test: "true",
    // },
    {
      id: "AgentApp",
      href: "https://play.google.com/store/apps/details?id=com.gbrapp.agriminer&pli=1?usp=sharing",
      title: "Agent App",
      description:
      "Agent is a robust agricultural application designed to bridge the gap between agricultural organizations and Agri-producers, as well as other users within the agricultural community. This innovative app serves as a central platform for managing farms and facilitating interactions between registered users, ultimately revolutionizing the way agriculture is conducted and managed.",
      image: Icon2,
      features: feature3,
    },
    // {
    //   id: "onMarketplace",
    //   href: "/onMarketplace",
    //   title: "onMarketplace",
    //   description:
    //     " GBR Farming's organic product marketplace offers a diverse selection of high-quality organic goods, from fresh produce to processed items, catering to health-conscious consumers while supporting sustainable farming practices.",
    //   image: Icon4,
    //   features: feature4,
    //   test: "true",
    // },

    // {
    //   id: "SocialonMarketplace",
    //   href: "/socialonMarketplace",
    //   title: "Social onMarketplace",
    //   description:
    //     " Our social marketplace facilitates interactions between businesses, enabling users to explore products and services, seek recommendations, and make informed buying decisions. Additionally, it encourages business-to-business networking, fostering strategic collaborations and partnerships.",
    //   image: Icon5,
    //   features: feature5,
    //   test: "true",
    // },

    // {
    //   id: "SocialiaaMarketplace",
    //   href: "/iaaMarketplace",
    //   title: "Social iaaMarketplace",
    //   description:
    //     "Social iaaMarketplace opens the door for investors to engage in agri-business and agri-asset investments, offering a seamless experience to allocate capital, diversify portfolios, and contribute to the agricultural sector's growth.",
    //   image: Icon6,
    //   features: feature6,
    //   test: "true",
    // },

  ];

  return (
    <>
      <Container>
        {appSectionData.map((e) => {
          return (
            <Row
              className="subSectionWrapper align-items-center pb-0"
              id={e.id}
            >
              <Col md={6}>
                <img className="techology-img mb-3 cursor-initial" src={e.image} />
                <h2 className="container-heading1 mt-0">{e.title}</h2>
                <p className="container-para mb-md-0 mb-3"> {e.description}</p>
                <Button
                  variant="contained"
                  color="primary"
                  className="registerBtn--mod technologyBtn mb-md-0 mb-4 mob-vis"
                  type="button"
                >
                  <a href={e.href} target="_blank"> {e.test ? "Explore now" : "Download App"} </a>
                </Button>
              </Col>
              <Col md={6}>
                <ul className="list-unstyled mb-0">
                  {e.features.map((e) => {
                    return (
                      <li className="d-flex">
                        <img
                          className="feauresIcon me-2"
                          src={TickMark}
                          alt="tick"
                        />
                        <p className="container-para">{e}</p>
                      </li>
                    );
                  })}
                </ul>

                <Button
                  variant="contained"
                  color="primary"
                  className="registerBtn--mod technologyBtn mb-md-0 mb-4 mob-hide  d-inline-block"
                  type="button"
                >
                  <a href={e.href} target="_blank"> {e.test ? "Explore now" : "Download App"} </a>
                </Button>
              </Col>
            </Row>
          );
        })}
      </Container>
    </>
  );
}
