import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import DigitalTrust from "../../../src/images/checking-production-bottled-refined-sunflower-oil-food-factory-production-line-min.jpg";
import Trading from "../../../src/images/gbrAbout/aboutimg4.webp";
import Blockchain from "../../../src/images/aboutImg/business-success-concept-wooden-table-top-view-hands-protecting-wooden-figures-people (1).jpg";
import Farming from "../../../src/images/IMA.jpg";
import Values from "../../../src/images/gbrAbout/aboutimg6.webp";
import CareImg from "../../../src/images/processing-entreprenur.jpg";
import TeamImg from "../../../src/images/gbrAbout/aboutimg7.webp";
import "react-awesome-slider/dist/styles.css";
import AboutBanner from "./AboutBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";

export default function AboutusComponent() {
  return (
    <>
      <HeaderComponent />
      <AboutBanner />
      <div className="about-main-wrapper">
        <AboutSubSection
          className="rowReverseAbout"
          // description="GBR create Social & Business Impact by transforming the Farmers into Agri-Entrepreneurs & Agri-Entrepreneurs into Agri-Business.
description="AgriProcessingHub generates social and economic impact by transforming  Processing entrepreneurs to become digital-enterprise."
          // description="AgriProcessingHub generates social and business impact by empowering farmers to become Agri-entrepreneurs through transformational initiatives."

          title="Why We Exist"
          src={Farming}
        />

        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          // description="GBR introducing MONEY MAKING ASSETS for Agri-Entrepreneurs."
        description="AgriProcessingHub is transforming the processing entrepreneurs landscape by introducing Plant Production Network that empower them to thrive and prosper."
          title="
        Who We Are"
          src={DigitalTrust}
        />

        <AboutSubSection
          className="rowReverseAbout"
          description="AgriProcessingHub care about Processing entrepreneurs who want to monetize unutilized resources."
          // description="GBR is dedicated to supporting farmers who aspire to transition into agri-entrepreneurs."

          title=" Whom We Care About"
          src={CareImg}
        />
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description={[
            " We manage plant production network for micro and small food manufacturing companies to monetize the unutilized capacity of processing unit and get orders from buyers/brands.",
          ]}
          // description1={[
          //   <strong>IMA:&nbsp;</strong>,
          //   ` Investable Machinery Assets for storage and processing companies (Agri-MSMEs).`,
          // ]}
          // description2={[
          //   <strong>IAA:&nbsp;</strong>,
          //   " Investable Agri Assets for agri-entrepreneurs .",
          // ]}

          // // description3={[
          // //   <strong>AAMC:&nbsp;</strong>,
          // //   "We enable Agri-entrepreneurs to manage agri-assets in their own farm to improve production.",
          // // ]}

          // description4={[
          //   <strong>onMarketplace:&nbsp;</strong>,
          //   "We provide organic D2C marketplace to Buy and Sell organic products",
          // ]}

          // description5={[
          //   <strong>Social onMarketplace:&nbsp;</strong>,
          //   "We provide a social B2B marketplace where businesses can interact with each other to make informed decisions about buying",
          // ]}

          // description6={[
          //   <strong>Social iaaMarketplace:&nbsp;</strong>,
          //   "We provide iaaMarketplace where investor can invest in to Agri-MSMEs and Investable assets ",
          // ]}
          // description4={[
          //   <strong>Community:&nbsp;</strong>,

          //   "We enable agri-entrepreneurs to manage the Organic certificate for the community (Producers). ",
          // ]}
          // description5={[
          //   <strong> Billing Services:&nbsp;</strong>,
          //   " Billing Services like invoice and purchase order management.",
          // ]}
        
         

          // description6={[
          //   <strong> Ecosystem:&nbsp;</strong>,
          //   "Ecosystem for agri-entrepreneurs to get advise , buy, sell and create trust.",
          // ]}
          // description7={[
          //   <strong>Buy back services: &nbsp;</strong>,
          //   "Buy back option for approved agri-entrepreneurs.",
          // ]}
          title="What We Do"
          src={Trading}
        />
        <AboutSubSection
          className="rowReverseAbout"
          description="AgriProcessingHub build network of approved processing entrepreneurs who will become our extended team to execute private-label and /or white-label orders as per the listed guidelines by our expert team."
          title="How We Do It"
          src={Blockchain}
        />

        {/* <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description16="Innovation and Value Creation"
          description1="We continuously work on VALUE CREATION for AgriMSMEs
        through technology as an enabler. We encourage Innovation and Challenge the status quo."
          description17="Passion"
          description8="We are ambitious and believe in Teamwork
       to achieve more when we collaborate and all work together."
          description18="Courage and Trust"
          description12=" We are willing to take risks and
        Win-Win Partnerships With Our Business Partners. We view
        AgriMSMEs Service Providers and financial partners as trustworthy
        allies in serving farmers."
          description19="Equality"
          description20="We treat AgriMSMEs and Partners with
        respect, fairness, transparency and integrity. We embrace Diversity of experience, Culture and Opinions and belive in sharing as learning for everyone."
          title="Our Culture & Values"
          src={Values}
        />
        <AboutSubSection
          className1="pb-0"
          className="rowReverseAbout "
          description="Our exceptional team, from dedicated employees to visionary leaders, fuels our success and propels us towards new heights. With open communication, trust, and collaboration at the core, we embrace innovation and achieve collective greatness. Together, we are shaping the future and proudly driving AgriProcessingHub forward."
          title="
        Our Team"
          src={TeamImg}
        /> */}
      </div>

      <FooterComponent />
    </>
  );
}



