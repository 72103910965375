import React from "react";
import { Col, Row } from "react-bootstrap";
import PricingCards from "../PricingCards/PricingCards";

export default function ProductionManager() {
  // const freeFeature = [ "Manage upto 10 growers", "Monetize Storage Capacity", "Monetize farming capacity(Manage up to 100 assets) ", "Feed management", "Asset Relocation Support", "Can raise the loan request", "Buy Back Support", "Request for labour card", "Care service for distibuted assets",  "online Report generation"];
  // const BasicFeature = [  "Manage upto 10 growers", "Monetize Storage Capacity", "Monetize farming capacity(Manage up to 100 assets) ", "Feed management", "Asset Relocation Support", "Can raise the loan request", "Buy Back Support", "Request for labour card", "Care service for distibuted assets", "Request for CBO business account", "online Report generation"];
//   const PremiumFeature = ["Create & Manage up to 100 sites & 50 Farmers","Billing", "Manage up to 5 Growers & 500 Assets","Automatic report creation for Growers"];
// const CoustomizedFeature = ["Customize the number of Farmers & Sites","Billing", "Customize the number of Growers & Assets ","Automatic report creation for Growers"];
const BasicFeature = ["Request for contract manufacturing",  "Online Report generation"];

const StandardFeature = ["Request for contract manufacturing", "Can raise the loan request",  "Online Report generation","Request for CBO (Agri-producer aggregator) account" , "Request for brand (Aggregator of processing entrepreneur)", "Can raise advisory request", "Can Store request"];



  const data = [

    // {
    //   title: "Free",
    //   annually: "9588",
    //   btnText: "Sign up ",
    //   price: "799",
    //   features: freeFeature,
    //   href:"/auth/3/signup",
    //   onClick: function() {
    //     localStorage.setItem("userType", "FF");
    //   }
    // },
    // {
    //   title: "Standard",
    
    //   annually: "17988",
    //   btnText: "Sign up ",
    //   price: "1499",
    //   features: BasicFeature,
    //   href:"/auth/3/signup",
    //   onClick: function() {
    //     localStorage.setItem("userType", "FF");
    //   }
    // },

    {
        title: "Free",
        annually: "0",
        btnText: "Sign up ",
        price: "0",
        features: BasicFeature,
        href:"/auth/3/signup",
        onClick: function() {
          localStorage.setItem("userType", "Production-Manager");
        },
        // data:"BASIC"
      },

      {
        title: "Standard",
        annually: "19188",
        btnText: "Sign up ",
        price: "1599",
        features: StandardFeature,
        href:"/auth/3/signup",
        onClick: function() {
          localStorage.setItem("userType", "Production-Manager");
        },
        data:"STANDARD"
      },
  ];

  return (
    <>
      <Row className="justify-content-center">
        {data.map((e) =>{
         return   <Col lg={4} md={6} sm={12} className="d-flex  mb-4">
                <PricingCards   data= {e.data} onClick={e.onClick} href={e.href} newTab={e.newTab} title={e.title} annually={e.annually} price={e.price} btnText={e.btnText} features={e.features} customText={e.customText}/>
            </Col>
        })}
      </Row>
    </>
  );
}
