import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../../images/MSME_Needs_21-11-2023__1_-removebg-preview.png";
import Ecosystem from "../../../images/challangesImg/blockchain.svg";
import Technology from "../../../images/challangesImg/technology.svg";

import Finance from "../../../images/challangesImg/income.svg";
import loan from "../../../images/challangesImg/loan.svg";

import "./ChallangesSection.css";

export default function ChallengesSection() {
  const challanges =[{
    title:"Extra Source of Income",
    description:"An additional stream of earnings is sought after by micro-small organisations to bolster financial stability and tackle income uncertainties.",
    image:Finance
  },

  // {
  //   title:"Marketing ",
  //   description:"Farmers lack effective marketing tools, highlighting a crucial need for promoting and selling their products. ",
  //   image:Marketing
  // },
  {
    title:"Loan",
    description:"Securing loans is a vital necessity for micro-small organisations, to address their financial needs and to enable them to invest in and improve their agro-storage practices for sustained productivity.",
    image:loan
  },
  {
    title:"Eco-system",
    description:"For storage providers, an ecosystem is crucial for streamlined supply chain coordination with farmers, transporters, and markets, enabling access to vital information, technology, and collaboration for enhanced operational effectiveness.    ",
    image:Ecosystem
  },

  // {
  //   title:"Brand ",
  //   description:"Branding empowers agri-producers to establish unique identities for their products, enabling differentiation and market recognition. ",
  //   image:Brand
  // }
 {
    title:"Easy Technology adoption ",
    description:"A pressing need for agri-storage providers is affordable and user-friendly technology adoption to enhance operational efficiency and ensure seamless integration into their storage practices",
    // description:"Affordable and user-friendly integrated agricultural technologies, enhancing productivity and efficiency on farms, are increasingly sought after by  micro-small organisations.",
    image:Technology
  }
]
  return (
    <Container className="challenge-container subSectionWrapper">
      <h2 className="container-title mb-md-5 text-center">
        {" "}
    Agri-Storage MSME needs
      </h2>

      <Row className="align-items-center">
        <Col md={4}>
          <img src={challangeImg} alt="challange Image" className="mb-md-0 mb-4" />
        </Col>

        <Col md={8}>
         

          <Row className="challangesRow justify-content-between ">
            {challanges.map((e)=>{
              return <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards  mb-md-4 ">
                <img src={e.image} alt="Challange Icon" className="mb-3" />
                <h6 className="mb-2">{e.title}</h6>
                <p className="mb-0">
                 {e.description}
                </p>
              </div>
            </Col>
           
            })}          
          </Row>
   
        </Col>
      </Row>
    </Container>
  );
}
