import React from "react";
import { Col, Row } from "react-bootstrap";
import PricingCards from "../PricingCards/PricingCards";

export default function FFPricingData() {
  const freeFeature = ["Monetize farming capacity(Manage up to 100 assets)", "Feed management", "Asset Relocation Support", "Can raise the loan request", "Buy Back Support", "Care service for distibuted assets"];
  const BasicFeature = ["Monetize farming capacity(Manage up to 100 assets)", "Feed management", "Asset Relocation Support", "Can raise the loan request", "Buy Back Support", "Care service for distibuted assets", "Request for OFC (Corporate farming) account"];
  const StandardFeature = ["Manage upto 10 growers","Monetize Processing Capacity", "Monetize Storage Capacity","Monetize farming capacity(Manage up to 100 assets) ", "Feed management", "Asset Relocation Support", "Can raise the loan request", "Buy Back Support", "Request for labour card", "Care service for distibuted assets", "Request for CBO to create & manage own farmer network" , "Request for Food processor account to start marketing & branding","online Report generation"];
  const PremiumFeature = ["Create & Manage up to 100 sites & 50 Farmers","Billing", "Manage up to 5 Agri-entrepreneur & 500 Assets","Automatic report creation for Growers"];
  const CoustomizedFeature = ["Customize the number of Farmers & Sites","Billing", "Customize the number of Agri-entrepreneur & Assets ","Automatic report creation for Growers"];



  const data = [

    {
      title: "Basic",
      annually: "0",
      btnText: "Sign up ",
      price: "0",
      features: freeFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "Grower");
      },
      data:"BASIC"
    },
    {
      title: "Standard",
      annually: "5988",
      btnText: "Sign up ",
      price: "499",
      features: BasicFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "Grower");
      },
      data:"STANDARD"
    },

    // {
    //     title: "For Production Manager",
    //     annually: "11988",
    //     btnText: "Sign up ",
    //     price: "999",
    //     features: StandardFeature,
    //     href:"/auth/3/signup",
    //     onClick: function() {
    //       localStorage.setItem("userType", "FF");
    //     }
    //   },
  ];

  return (
    <>
      <Row className="justify-content-center">
        {data.map((e) =>{
         return   <Col lg={4} md={6} sm={12} className="d-flex  mb-4">
                <PricingCards data= {e.data}onClick={e.onClick} href={e.href} newTab={e.newTab} title={e.title} annually={e.annually} price={e.price} btnText={e.btnText} features={e.features} customText={e.customText}/>
            </Col>
        })}
      </Row>
    </>
  );
}
