import React from "react";
import RealTimeVisibility from "../../../images/dtn-network-icons/real-time-visibility.svg";
import ImprovedQuality from "../../../images/dtn-network-icons/improved-quality.svg";
import Traceability from "../../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";
import Icon4 from "../../../images/gbrFarmerBenefits/increase.svg";
import Icon5 from "../../../images/gbrFarmerBenefits/save-money.svg";
import Icon1 from "../../../images/gbrFarmerBenefits/pay-per-click.svg";
import Icon2 from "../../../images/gbrFarmerBenefits/economic-growth.svg";

import Icon7 from "../../../images/gbrFarmerBenefits/smart-farm.svg";
import Icon6 from "../../../images/gbrFarmerBenefits/breakdown.svg";
import Icon10 from "../../../images/global-market.svg";
export default function ProductionNetworkBenefits() {
  const benefits = [

   
     
    {
      img: RealTimeVisibility,
      title: "Real Time Visibility",
      bodycopy:
      " Real-time visibility is a core benefit of PPN Network, offering users immediate access to crucial data and insights across processing operations, enabling informed decisions and maximizing efficiency.",
    },
    {
        img: Traceability,
        title: "Traceability",
        bodycopy: " Traceability is a distinct advantage of PPN Network, ensuring transparent tracking of processing products throughout the supply chain, enhancing accountability and quality assurance for consumers and producers alike."    },
     
        {
          img: ImprovedQuality,
          title: "Improve Quality Production",
          bodycopy: "Enhanced quality production is a key benefit of PPN Network, utilizing data-driven insights and advanced tech to optimize processing processes, yielding superior results."    },

             
    // {
    //   img: Icon4,
    //   title: "Grow agri-assets & Earn more",
    //   bodycopy:
    //     "Cultivate and manage agricultural assets on behalf of a third party, leveraging expertise to optimize growth and enhance returns.",
    // },
    {
      img: Icon10,
      title: "Access to Market ",
      bodycopy:
      "Access to local and global markets empowers processing entrepreneur with expanded opportunities for sales and economic growth."
    },
    // {
    //   img: Icon6,
    //   title: "Access to Machinery & agri-assets",
    //   bodycopy:
    //     "Processing entrepreneur benefit from facilitated access to machinery & agri-assets, enhancing operational efficiency and productivity."
    // },

    {
        img: Icon5,
        title: "Access to Finance ",
        bodycopy:
          "Processing entrepreneur gain access to finance through our support, facilitating investments in their operations and nurturing sustainable growth.",
      },
    {
      img: Icon7,
      title: "Access to Technology ",
      bodycopy:
        "AgriProcessingHub provides processing entrepreneur with enhanced access to technology, fostering efficiency and innovation in processing practices. "
    },
    {
      img: Icon1,
      title: "Monetize unutilized capacity ",
      bodycopy:
        "Monetizing unutilized capacity allows processing entrepreneur to boost earnings by efficiently using their operational capabilities, unlocking additional revenue streams and improving overall profitability. "
    },
    // {
    //   img: Icon2,
    //   title: "Improve Top line",
    //   bodycopy:
    //     "Experience exponential growth as sales revenue soars to new heights, unlocking unprecedented success for your business. "
    // },
          
  ];
  return (
    <div className="main-wrapper2">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Benefits
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col key={index} md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
