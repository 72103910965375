import React from 'react'
import StepCards from '../stepCards/StepCards'
import step1 from "../../images/ae-journey/ae1.png";
import step2 from "../../images/ae-journey/ae2.png";

import step3 from "../../images/step2.png";
import step4 from "../../images/ae-journey/ae3.png";
import step5 from "../../images/ae-journey/ae4.png";
import img5 from "../../images/ae-journey/ae5.png";
import img1 from "../../images/arrow.svg";
import { Row, Col } from "react-bootstrap";
import { Container } from '@material-ui/core';

export default function CMProcessCards() {
    const stepCardContent = [
      {title: "Discover AgriProcessingHub",
      bodycopy : "Discover AgriProcessingHub either through offline or online channels (e,g. we are part of CMN.Network)"
    },

        {
          title: "Brochure Review",
          bodycopy: "AgriProcessingHub provides a brochure of the products it manufactures.",
        },
        {
          title: "Product Selection",
          bodycopy: "The brand selects a product from the brochure.",
          frontarrow:"true"

        },
        {
          title: "Raise RFQ",
          bodycopy: "Brand will send RFQ for selected product.",
          backArrow: "true"
        },
        {
          title: "Manufacturing Assignment",
          bodycopy: "AgriProcessingHub assigns a processing unit  for the selected product. ",
          backArrow: "true"

        },
        {
          title: "Sample Submission",
          bodycopy:
            " AgriProcessingHub sends a product sample to the brand.",
            backArrow: "true"

        },
        {
          title: "Brand Approval",
          bodycopy:
          "AgriProcessingHub receives approval from the brand for manufacturing.",
          backArrow: "true"
        },
        {
            title: "Purchase Order",
            bodycopy:
              "The brand issues a purchase order for the approved product.              ",
              backArrow: "true"

          },

          {
            title: "Manufacturing Start",
            bodycopy:
              "AgriProcessingHub begins manufacturing based on the purchase order.              ",
              backArrow: "true"

          },
          {
            title: "Order Status Updates",
            bodycopy:
              "AgriProcessingHub provides regular order status updates to the brand.              ",
              backArrow: "true"

          },
          {
            title: "Quality Testing",
            bodycopy:
              "AgriProcessingHub conducts quality testing on the manufactured product.",
              backArrow: "true"

          },
          {
            title: "Transport Arrangement",
            bodycopy:
              " AgriProcessingHub arranges transportation for the product.",
              backArrow: "true"

          },
          {
            title: "Final Delivery",
            bodycopy:
              " AgriProcessingHub delivers the order to the brand.",
              backArrow: "true"

          },

          {
            title: "Remaining Payment",
            bodycopy:
              " Brand will issue the remaining payment to AgriProcessingHub.",
              backArrow: "true"

          },
      ];
  return (
    <>
    <h2 className="text-center container-title mb-4">
Our Process  </h2>
<Container>
    <div className='d-flex flex-wrap p-0 pb-5'>
         
    {stepCardContent.map((e, i) =>{
               return (
                <>
                <div className='process-container d-flex align-items-center'>
                  {    e.backArrow &&              <div className="process-container-img-container"><img src={img1}/></div> // Arrow between cards
}
                <StepCards
                classname="process-container-card"
                  src={e.img}
                  stepTitle={e.title}
                  stepContent={e.bodycopy}
                />
               {!e.backArrow && i < stepCardContent.length - 1 && !e.frontarrow &&(
                  <div className="process-container-img-container"><img src={img1}/></div> // Arrow between cards
                )}
                </div>
</>
              );
        })}    </div>

</Container>
        </>
  )
}
