import React from "react";
import img1 from "../../images/optionalChoice.png";
import img2 from "../../images/improvement.png";
import img3 from "../../images/job-offer.png";
import img4 from "../../images/double-check.png";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function AgrientrepreneurContribution() {
  const benefits = [
    {
      img: img1,
      title: "Create Jobs",
      bodycopy: "Processing entrepreneurs create jobs by establishing facilities that boost local economies and enhance supply chains.",
    },
    {
      img: img2,
      title: "Improve Economy",
      bodycopy: "Processing entrepreneurs improve the economy by increasing local production, creating jobs, and enhancing the value of raw materials.",
    },
    // {
    //   img: img3,
    //   title: "Create Jobs",
    //   bodycopy:
    //     "Investable trade concept for agriculture asset class will help to create more Rural jobs.",
    // },
  ];
  return (
    <div className=" subSectionWrapper">
      <h2 className="text-center container-title">
      Processing entrepreneur Contribution
      </h2>
      <Row className="justify-content-center">
        {benefits.map((e) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
