


import React from "react";
import AssetManagement from "../../images/gbr-farming-services/asset-management.jpg";
import ContractFarming from "../../images/gbr-farming-services/contract-farming.jpg";
import ContractManufacturing from "../../images/gbr-farming-services/contract-manufacturing.jpg";
import { NavLink } from "react-router-dom";
import "./GBRFarmingServices.css"
import { Row, Col } from "react-bootstrap";
import { Container } from "@material-ui/core";

export default function GBRFarmingServices() {
  const services = [
    // {
    //   title: "ABN",
    //   image: ABN,
    //   href: "/ABN",
    // },
    // {
    //   title: "CBN",
    //   image: CBN,
    //   href: "/CBNNetwork",
    // },

    {
      title: "Asset Management",
      image: AssetManagement,
      href: "/asset-management-services",
    },
    {
        title: "Contract Services",
        image: ContractFarming,
        href: "/contract-services",
      },
      {
        title: "Manufacturing onDemand (MoD)",
    image: ContractManufacturing,
    href: "/MoD",
      },
  ];

  return (
    <Container className="container for-farmer-section2 subSectionWrapper ">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
          return (
            <Col md={4}>
              <NavLink to={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-0 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
