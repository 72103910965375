import React from "react";
import Icon4 from "../../images/gbrFarmerBenefits/increase.svg";
import Icon5 from "../../images/gbrFarmerBenefits/save-money.svg";
import Icon7 from "../../images/gbrFarmerBenefits/pay-per-click.svg";
import Icon8 from "../../images/gbrFarmerBenefits/smart-farm.svg";
import Icon10 from "../../images/global-market.svg";

import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function ForAgriTransporterBenefits() {
  const benefits = [
   
    {
      image: Icon4,
      title: "Efficient Operations",
      bodycopy:
        "GBR Farming  provide tools and technologies that enable agricultural transporters to operate more efficiently. This could include features such as real-time tracking, digital documentation, and optimized route planning, all of which contribute to cost savings and improved service quality. ",
    },
    {
      image: Icon10,
      title: "Access to a Broader Market  ",
      bodycopy:
      "unutilized  Capacity  platform (AAMC) act as a marketplace connecting agricultural transporters with a broader range of potential clients and therefore opening up new markets and opportunities for transporters to expand their services."
    },
    // {
    //   image: Icon6,
    //   title: "Access to Machinery ",
    //   bodycopy:
    //     "Progressive farmers & micro small organisations benefit from facilitated access to machinery, enhancing operational efficiency and productivity."
    // },
    // {
    //   image: Icon7,
    //   title: "Monetize unutilized selling capacity ",
    //   bodycopy:
    //     "Monetizing unutilized farming capacity through contract manufacturing offers farmers the opportunity to leverage their resources for additional income and increased profitability"
    // },
    // {
    //   image: Icon8,
    //   title: "Access to farmers facilitation services ",
    //   bodycopy:
    //     "Experience the advantages of our seamless field support, including migration assistance, asset care, labor card facilitation, and buyback support. This comprehensive package ensures operational efficiency and provides peace of mind for farmers."
    // },
  ];
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={6}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </Container>

  );
}
