
import React from "react";
import HoneyAsset from "../../images/1 (1).png";
import Community from "../../images/forMSMEBanner.webp";
import ProcessingSupport from "../../images/bee-nests-flower-garden.jpg"
import { NavLink } from "react-router-dom";

import { Row, Col } from "react-bootstrap";

export default function AAMCServices() {
  const services = [
    {
      title: "AAMC",
      image: Community,
      href: "/AAMC",
    },
   
    {
        title: "onMarketplace",
        image: HoneyAsset,
        href: "/onMarketplaceSell",
      },

      // {
      //   title: "Social onMarketplace",
      //   image: FieldSupport,
      //   href: "/socialonMarketplace",
      // },

      {
        title: "Social iaaMarketplace",
        image: ProcessingSupport,
        href: "/iaaMarketplace",
      },
  ];

  return (
    <div className="container for-farmer-section2 px-0 subSectionWrapper pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
          return (
            <Col md={4}>
              <NavLink to={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
