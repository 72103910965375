import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../images/MSME Needs 18-12-2023.png";
import Ecosystem from "../../images/MarketAccess.svg";
import Technology from "../../images/challangesImg/technology.svg";

import Finance from "../../images/challangesImg/income.svg";
import loan from "../../images/challangesImg/loan.svg";

const FarmerChallangeSection = () => {
    const challanges =[{
        title:"Extra Source of Income",
        description:"An additional stream of earnings is sought after by agri-producers to bolster financial stability and tackle income uncertainties.",
        image:Finance
      },
    
      // {
      //   title:"Marketing ",
      //   description:"Farmers lack effective marketing tools, highlighting a crucial need for promoting and selling their products. ",
      //   image:Marketing
      // },
      {
        title:"Loan",
        description:"Securing loans for asset relocation such as bee migration  and buy inputs such as feed  are necessity for agri-producers, to address their financial needs and to enable them to invest in and improve their agricultural practices for sustained productivity",
        image:loan
      },
      {
        title:"Market Access",
        description:"Agri-producer face the challenge of limited market access to sell produce such as honey, hindering their ability to reach a broader audience and maximize the potential for their agricultural products.",
        image:Ecosystem
      },
    
      // {
      //   title:"Brand ",
      //   description:"Branding empowers agri-producers to establish unique identities for their products, enabling differentiation and market recognition. ",
      //   image:Brand
      // }
     {
        title:"Easy Technology adoption ",
        description:"Affordable and user-friendly integrated agricultural technologies to manage agri-assets such as Beehive, enhancing productivity and efficiency on farms, are increasingly sought after by agri-producers.",
        image:Technology
      }
    ]
    return(
        <Container className="challenge-container subSectionWrapper">
        <h2 className="container-title mb-md-5 text-center">
          Progressive Farmers Needs
        </h2>
  
        <Row className="align-items-center">
          <Col md={4}>
            <img src={challangeImg} alt="challange Image" className="mb-md-0 mb-4" />
          </Col>
  
          <Col md={8}>
           
  
            <Row className="challangesRow justify-content-between ">
              {challanges.map((e)=>{
                return <Col md={6} sm={6} xs={10} className="d-flex">
                <div className="challangeCards  mb-md-4 ">
                  <img src={e.image} alt="Challange Icon" className="mb-3" />
                  <h6 className="mb-2">{e.title}</h6>
                  <p className="mb-0">
                   {e.description}
                  </p>
                </div>
              </Col>
             
              })}          
            </Row>
     
          </Col>
        </Row>
      </Container>
    )
};

export default FarmerChallangeSection
