import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../images/Agri-Retailers_challenges_26-12-2023-removebg-preview.png";

import Monetize from "../../images/Monetize manufacturing capacity.svg";
import Order from "../../images/delivery.svg";

const AgriRetailerChallenge = () => {
    const challanges =[{
        title:"Monetize Selling capacity",
        description:"Limited resources hinder micro-small organizations from effectively monetizing their full capacity in sales, impeding their potential for revenue generation.        ",
        image:Monetize
      },
    
 
      {
        title:"Getting more business (orders)",
        description:"The constant struggle to get more orders is a big challenge for processing entrepreneur, requiring smart strategies and constant innovation to stand out in a competitive market.",
        image:Order
      },
    //   {
    //     title:"Market Access",
    //     description:"Farmers face the challenge of limited market access, hindering their ability to reach a broader audience and maximize the potential for their agricultural products.",
    //     image:Ecosystem
    //   },
    
      // {
      //   title:"Brand ",
      //   description:"Branding empowers agri-producers to establish unique identities for their products, enabling differentiation and market recognition. ",
      //   image:Brand
      // }
    //  {
    //     title:"Easy Technology adoption ",
    //     description:"Affordable and user-friendly integrated agricultural technologies, enhancing productivity and efficiency on farms, are increasingly sought after by agri-producers.",
    //     image:Technology
    //   }
    ]
    return(
        <Container className="challenge-container subSectionWrapper">
        <h2 className="container-title mb-md-5 text-center">
          Agri-Retailer challenges
        </h2>
  
        <Row className="align-items-center">
          <Col md={4}>
            <img src={challangeImg} alt="challange Image" className="mb-md-0 mb-4" />
          </Col>
  
          <Col md={8}>
           
  
            <Row className="challangesRow justify-content-between ">
              {challanges.map((e)=>{
                return <Col md={6} sm={6} xs={10} className="d-flex">
                <div className="challangeCards  mb-md-4 ">
                  <img src={e.image} alt="Challange Icon" className="mb-3" />
                  <h6 className="mb-2">{e.title}</h6>
                  <p className="mb-0">
                   {e.description}
                  </p>
                </div>
              </Col>
             
              })}          
            </Row>
     
          </Col>
        </Row>
      </Container>
    )
};

export default AgriRetailerChallenge
