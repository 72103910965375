import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
// import "./CareServices.css";

export default function ContractManufacturingService() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner bannerText="Contract manufacturing Services" className="contract-manufacturing-service-bg" />
      <div className="main-wrapper2">
        <div className="subSectionWrapper">
          <HomeSubSection
            className3="advisory-service"
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards"
            descriptionTitle="For Brands"
            description="GBR Farming offers contract manufacturing services for brands, ensuring they receive high-quality agricultural products processed to their specifications. By handling the entire manufacturing process, GBR Farming allows brands to focus on their core business activities while guaranteeing consistent, reliable outputs. This partnership supports brands in maintaining quality standards and meeting market demands efficiently."
           
          />

          <HomeSubSection
            className="rowReverseAbout borderedCards borderedCards"
            className3="fieldSupportService1"
            description="   GBR Farming provides contract manufacturing services to processing entrepreneur  by offering them contract manufacturing orders, allowing them to monetize their unutilized manufacturing capacity. Through this service, processing entrepreneur receive specific production tasks and standards to meet, ensuring a steady stream of work and income. GBR Farming manages the logistics and coordination, enabling manufacturers to optimize their resources and enhance productivity while maintaining high-quality outputs for various brands."         
          
            descriptionTitle="For Processing Entrepreneur "
          />
        
        </div>
      </div>
      <FooterComponent />
    </>
  );
}
