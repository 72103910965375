import React from "react";
import { Col, Row } from "react-bootstrap";
import PricingCards from "../PricingCards/PricingCards";

export default function AgriRetailerPricing() {
  const freeFeature = [ "Manage upto 10 Agri-entrepreneur", "Can raise the loan request", "Care service for distributed assets",  "online Report generation"];
  const BasicFeature = [  "Manage upto 100 Agri-entrepreneur", "Can raise the loan request", "Care service for distributed assets", "online Report generation","Request for CBO (Agri-producer aggregator) account" , "Request for ADN ( Aggregator of agri-retailer) account","Request for agri-input"];
  // const StandardFeature = ["Manage upto 10 Agri-entrepreneur","Monetize Processing Capacity", "Monetize selling Capacity","Monetize farming capacity(Manage up to 100 assets) ", "Feed management", "Asset Relocation Support", "Can raise the loan request", "Buy Back Support", "Request for labour card", "Care service for distributed assets", "Request for CBO & ADN business account" , "Request for Food processor account to start marketing & branding","online Report generation"];
  // const PremiumFeature = ["Create & Manage up to 100 sites & 50 Farmers","Billing", "Manage up to 5 Agri-entrepreneur & 500 Assets","Automatic report creation for Growers"];
  // const CoustomizedFeature = ["Customize the number of Farmers & Sites","Billing", "Customize the number of Agri-entrepreneur & Assets ","Automatic report creation for Growers"];



  const data = [

    {
      title: "Basic",
      annually: "9588",
      btnText: "Sign up ",
      price: "799",
      features: freeFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "FF");
      },
      data:"BASIC"
    },
    {
      title: "Standard",
    
      annually: "17988",
      btnText: "Sign up ",
      price: "1499",
      features: BasicFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "FF");
      },
      data:"STANDARD"
    },

    // {
    //     title: "For Production Manager",
    //     annually: "11988",
    //     btnText: "Sign up ",
    //     price: "999",
    //     features: StandardFeature,
    //     href:"/auth/3/signup",
    //     onClick: function() {
    //       localStorage.setItem("userType", "FF");
    //     }
    //   },
  ];

  return (
    <>
      <Row className="justify-content-center">
        {data.map((e) =>{
         return   <Col lg={4} md={6} sm={12} className="d-flex  mb-4">
                <PricingCards  data= {e.data} onClick={e.onClick} href={e.href} newTab={e.newTab} title={e.title} annually={e.annually} price={e.price} btnText={e.btnText} features={e.features} customText={e.customText}/>
            </Col>
        })}
      </Row>
    </>
  );
}
