import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./FieldSupportService.css";
import FieldSupportServiceSection1 from "./FieldSupportServiceSection1";
import FArmerCTATab from "../FarmerCTATab/FArmerCTATab";

export default function FieldSupportService() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="fieldSupportServiceBanner"
        bannerText={["Field Support Service"]}
      />
      <div>
        <FieldSupportServiceSection1 />
        <FArmerCTATab />
      </div>
      <FooterComponent />
    </>
  );
}
