import React from "react";
import FarmerImg from "../../../images/asian-woman-farmer-using-digital-tablet.jpg";
import OperatorImg from "../../../images/gbrPN/PNImg3.webp";
import QAImg from "../../../images/gbrPN/PNImg2.webp";
import ProductionManagerImg from "../../../images/gbrPN/PNImg5.webp";
import FarmersFranchiseImg from "../../../images/gbrPN/PNImg5.webp"
import ExecutiveImg from "../../../images/gbrPN/PNImg4.webp";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import HeaderComponent from "../Header/HeaderComponent";
import NetworkBanner from "../NetworkBanner/NetworkBanner";

import FooterComponent from "../Footer/FooterComponent";
import IAABenefit from "../IAABenefit/IAABenefit";
import IAAManagement from "../IAAManagement/IAAManagement";
import PNServices from "../../OurServices/PNServices";
import ExploreTechnologySection1 from "../../ExploreTechnology/ExploreTechnologySection1";
import MoneyMakingAssets from "../../ForFarmers/MoneyMakingAssets";
import AAMCNetworkSection1 from "./AAMCNetworkSection1";
import AAMCNetworkServices from "./AAMCNetworkServices";

export default function AAMCNetwork() {
  return (
    <>
      <HeaderComponent />
      <NetworkBanner bannerTitle="Agri Asset Management Cloud for Digital Transformation in Farming sector" bodycopy="Job creation | Digital transformation | Brand differentiation | Investable asset management | Organic certification" className="pnbg" />
      <div className="subSectionWrapper pb-0">
      <AAMCNetworkSection1/>
      </div>
      {/* <AAMCNetworkServices title="Agri Asset Management Cloud Services"/> */}
      {/* <MoneyMakingAssets /> */}

      {/* <IAABenefit />
      <IAAManagement /> */}
      <div className="network-wrapper">
        <h2 className="container-title text-center mt-4">Rural-Entrepreneur Team </h2>
        {/* <h4 className="container-title text-center mx-4">
        Production Network (PN) - Investable Asset Management network for MSMEs
      </h4>
      <p className="container-para text-center mx-md-0 mx-4 mb-4 ">MSME can Build, Own, Manage & Operate Production Network</p> */}

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image11"
          description="At the heart of our agricultural network, agri-retailers stand as the driving force. Beyond selling agri-inputs, they diligently oversee and manage our verified agri-entrepreneurs and assets, playing an indispensable role in the success of our eco-system.
          "
          descriptionTitle="
              Agri-Retailers"
          src={FarmerImg}
          href2="auth/3/signin"
          buttoncont2="Sign in"
          buttoncont7='Sign up'

          onClick={() => {
            localStorage.setItem("userType", "FF");
          }}              href7="/auth/3/signup"
        />

        <HomeSubSection
        className1="aboutBgGrey"
        className="rowReverseAbout--mod"
        descriptionTitle="
        Agri-Entrepreneur"
        description="Agri-Entrepreneurs are the backbone of our agricultural network, serving as the linchpin in our ecosystem's success. These franchisees are responsible not only for land cultivation but also for managing and overseeing our network of verified growers and valuable assets. Their role is pivotal in maintaining the integrity and productivity of our agricultural operations.
      "
        src={FarmersFranchiseImg}
        // href2="auth/3/signup"
        // buttoncont2="Register"
        // href1="auth/3/signin"
        // buttoncont1="Sign In"

        // href7='http://cbn.gbrapp.com/'
        href2="auth/3/signin"
        buttoncont2="Sign in"
        buttoncont7='Sign up'

        onClick={() => {
          localStorage.setItem("userType", "FF");
        }}              href7="/auth/3/signup"
      />


        {/* <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image34"
          description="Production managers within our system play a multifaceted and essential role. They are responsible for supervising contract manufacturing, ensuring efficient cultivation practices, and managing a network of growers and valuable assets, all crucial components of our operation's success.
              "
          descriptionTitle="
              Production Manager Role"
          src={ProductionManagerImg}
          // href2="auth/3/signup"
          // buttoncont2="Register"
        //   href4='http://cbn.gbrapp.com/'
        //   buttoncont1='Sign In'

        // href7='http://cbn.gbrapp.com/'
        href2="auth/3/signin"
        buttoncont2="Sign in"
        buttoncont7='Sign up'

        onClick={() => {
          localStorage.setItem("userType", "Production-Manager");
        }}              href7="/auth/3/signup"
        /> */}



      </div>
      <FooterComponent />
    </>
  );
}
