import React from "react";
import Icon4 from "../../../images/gbrFarmerBenefits/increase.svg";
import Icon5 from "../../../images/gbrFarmerBenefits/save-money.svg";
import Icon6 from "../../../images/gbrFarmerBenefits/breakdown.svg";
import Icon7 from "../../../images/gbrFarmerBenefits/pay-per-click.svg";
import Icon8 from "../../../images/gbrFarmerBenefits/smart-farm.svg";

// import "./ForFarmerSection3.css"

import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../../BenefitsCard/BenefitsCard";

export default function ForFarmerBenefits() {
  const benefits = [
   
    // {
    //   image: Icon4,
    //   title: "Grow agri-assets & Earn more",
    //   bodycopy:
    //     "Cultivate and manage agricultural assets on behalf of a third party, leveraging expertise to optimize growth and enhance returns.",
    // },
    {
      image: Icon5,
      title: "Access to Finance ",
      bodycopy:
        "Agri-producers gain access to finance through our support, facilitating investments in their operations and nurturing sustainable growth.",
    },
    
    // {
    //   image: Icon7,
    //   title: "Monetize un-utilized Capacity ",
    //   bodycopy:
    //     "Agri-producers can effectively monetize their previously unutilized capacity, turning resources into valuable income streams."
    // },
    {
      image: Icon8,
      title: "Access to Agri-producer facilitation services ",
      bodycopy:
        "Experience the advantages of our seamless field support, including migration assistance, asset care, labor card facilitation, and buyback support. This comprehensive package ensures operational efficiency and provides peace of mind for agri-producers."
    },
    {
      image: Icon6,
      title: "Access to Machinery ",
      bodycopy:
        "Agri-producers benefit from facilitated access to machinery, enhancing operational efficiency and productivity."
    },
    {
      image: Icon8,
      title: "Rental Modal ",
      bodycopy:
        "Rental modalities offer farmers flexibility in accessing equipment, reducing upfront costs and enhancing operational efficiency, ultimately improving agricultural productivity and profitability."
    },
  ];
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={6}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </Container>

  );
}
