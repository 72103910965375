import React from 'react';
import Icon1 from "../../images/ServiceIcons/bee-box.svg";
import Icon2 from "../../images/ServiceIcons/tractor (1).svg";
import Icon3 from "../../images/ServiceIcons/veterinarian.svg";
import Icon4 from "../../images/ServiceIcons/service.svg";
import Icon5 from "../../images/ServiceIcons/ecology.svg";
import Icon6 from "../../images/ServiceIcons/supply-chain-management.svg";

import OurServiceCardContainer from './OurServiceCardContainer';



export default function CBNServices() {
    const CBNServices = [
   
        {
          image: Icon1,
          title: " CBN-Agri Asset ",
          bodycopy:
            "Facilitate Agri asset distribution service for producers .",
        },
       


        {
          image: Icon2,
          title: " CBN-AgriMachinery ",
          bodycopy:
            "Facilitate machinery asset distribution service for producers.",
        },


        {
          image: Icon3,
          title: "CBN-AH/Agri-Clinic ",
          bodycopy:
            " Facilitate animal and plant care through farmer advisors and nutrition distributors. "
        },
     


        {
            image: Icon4,
            title: "CBN-CBO",
            bodycopy:
              "Create farmers club for Organic certification and local trade (buy & sell)."
          },

          {
            image: Icon5,
            title: "CBN-Organic input",
            bodycopy:
              "Easy access for farmers to buy organic feed from the approved organic feed providers."
          },
          {
            image: Icon6,
            title: "CBN-VCN (Local supply chain)",
            bodycopy:
              "Most efficient value chain service to buy from farmers and traders to create differentiated product."
          },
          
      ];
  return (
    <OurServiceCardContainer services={CBNServices}  title="CBN Services for AgriMSMEs Farmers"/>
  )
}
