import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import ForFarmerSection4 from "../ForFarmerSection4/ForFarmerSection4";
import "./ForFarmers.css";
import { Container } from "@material-ui/core";
import FArmerCTATab from "../FarmerCTATab/FArmerCTATab";
import TitleDescription from "../TitleDescription/TitleDescription";
import FarmerServices from "./FarmerServices";
import FarmerChallangeSection from "./FarmerChallangeSection";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import GrowerBenefits from "./GrowerBenefits";
import GrowersContribution from "./GrowersContribution";
import FFPricingData from "../pricingTabs/FFPricingData";
export default function ForFarmers() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="farmUpdateBanner text-white"
        bannerText={[
          "Embark on your earning journey now by efficiently ",
          <br className="d-md-block d-none"></br>,
          "managing profit-generating assets",
        ]}
        buttonText="Start Earning Now"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "Grower");
        }}
        roleType="NULL"
        DlBtn="true"
        dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.aamcapp"
        DownloadText="Download App"
      />

<div className="main-wrapper2 subSectionWrapper mb-0">
          <Container>
            <TitleDescription
              titleClass="text-start"
              classname2="text-start"
              classname="mb-0 mt-0 "
              title="Who are an Agri-Entrepreneur"
              description="An Agri-entrepreneur within the GBR Farming initiative is a progressive farmer who embraces various levels of entrepreneurship in the agri-food sector, strategically leveraging digital transformation to revolutionize the industry. GBR Farming acts as a transformative force, officially recognizing and approving each Agri-entrepreneur, thereby empowering traditional farmers to evolve and thrive in the dynamic landscape of agri-food entrepreneurship."
            />

            {/* <AgriBusinessCard /> */}
          </Container>
        </div>
      <FarmerChallangeSection />
      <SolutionEcosystem
        type="AE"
        bodycopy=" System and Process to convert Progressive farmers into Agri-entrepreneurs to address their needs."
      />
      <div>
    
        <Container>
          <FarmerServices />
        </Container>
        <div className="main-wrapper2">
          <GrowerBenefits />
        </div>
        <div className="subSectionWrapper">
          <Container>
            <ForFarmerSection4 />
          </Container>
        </div>
        <div className="main-wrapper2">
          <Container>
            <GrowersContribution />
          </Container>
        </div>

        <Container className="subSectionWrapper pb-0">
          <h2 className="text-center title mb-4 mb-lg-5 container-title">
            Pricing
          </h2>
          <FFPricingData />
        </Container>
        <FArmerCTATab />
      </div>
      <FooterComponent />
    </>
  );
}
