import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import CTA from "../CTA/CTA";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import AgriRetailerService from "./AgriRetailerService";
import AgriRetailerBenefits from "./AgriRetailerBenefits";
import AgriRetailerChallenge from "./AgriRetailerChallenge";
import AgriRetailersJourney from "./AgriRetailersJourney";
import AgriRetailerContribution from "./AgriRetailerContribution";
import AgriRetailerPricing from "../pricingTabs/AgriRetailerPricing";
export default function AgriRetailers() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="agriretailer-bg"
        bannerText={[
          "Want to become an agri-retailer to    ",
          <br className="d-lg-block d-none"></br>,
          " to sell inputs to our agri-entrepreneurs ",
        ]}
        roleType="NULL"
        buttonText="Start selling"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "Production-Manager");
        }}
        DlBtn="true"
        dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.aamcapp"
        DownloadText="Download App"
      />
      <AgriRetailerChallenge />
      <SolutionEcosystem
        type="AR"
        bodycopy="Eco-system to monetize the unutilized selling capacity to address the needs."
      />
      <div className="main-wrapper2 subSectionWrapper mb-0">
        <Container>
          <TitleDescription
            titleClass="text-start"
            classname2="text-start"
            classname="mb-0 mt-0 "
            title="Who are an Agri-Retailers"
            // description="GBR Farming's agri-retailers play a pivotal role in the agricultural value chain by acting as intermediaries. These retailers specialize in selling essential agri-inputs to agri-entrepreneurs, leveraging their own selling capacity. GBR Farming's agri-retailers focus on maximizing their own distribution capabilities and market presence to effectively monetize the sale of agricultural inputs. This strategic approach enables them to enhance profitability and establish a robust position in the agri-business landscape, contributing to the overall success of GBR Farming in the agricultural market.
            // "
            description="GBR Farming's agri-retailers are pivotal in the agricultural value chain, specializing in selling agri-inputs to entrepreneurs. Beyond distribution, they actively manage and advise on crop selection and agronomic practices, contributing to improved yields. By strategically maximizing distribution and market presence, these retailers enhance profitability and establish a strong position in the agri-business landscape, highlighting their crucial role in GBR Farming's overall success in the agricultural market."
          />

          {/* <AgriBusinessCard /> */}
        </Container>
      </div>
      <Container>
      <AgriRetailerService />
      </Container>
      <div className="main-wrapper2">
        <AgriRetailerBenefits />
      </div>

      {/* <div className="subSectionWrapper">
       
          <GrowerBenefits />
   
      </div> */}
     
      
          <div className="subSectionWrapper">
          <Container>
          <AgriRetailersJourney/>
          </Container>
        </div>
        <div className="main-wrapper2">
          <Container>
          <AgriRetailerContribution/>
          </Container>
        </div>
      <Container className="subSectionWrapper pb-0">
          <h2 className="text-center title mb-4 mb-lg-5 container-title">
            Pricing
          </h2>
          <AgriRetailerPricing />
        </Container>
      <Container>
        <div className=" subSectionWrapper">
          <CTA
            onClick={() => {
              localStorage.setItem("userType", "FF");
            }}
            href="/auth/3/signup"
            heading="Elevate Your Agricultural Expertise Join Us as a Agri-Retailer!"
            btnText="Register Now"
          />
        </div>
      </Container>
      <FooterComponent />
    </>
  );
}
