import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../../images/MSME_Needs_19-12-2023__1_-removebg-preview.png";
import Ecosystem from "../../../images/challangesImg/blockchain.svg";
import Brand from "../../../images/challangesImg/brand.svg";
import Technology from "../../../images/bar-chart.svg";

import Marketing from "../../../images/challangesImg/megaphone.svg";
import Finance from "../../../images/MarketAccess.svg";
import loan from "../../../images/challangesImg/loan.svg";

// import "./ChallangesSection.css";

export default function MSMEChallenges() {
  const challanges =[

  // {
  //   title:"Marketing ",
  //   description:"Farmers lack effective marketing tools, highlighting a crucial need for promoting and selling their products. ",
  //   image:Marketing
  // },
  {
    title:"Market Access",
    description:"MSMEs face the challenge of limited market access, hindering their ability to reach a broader audience and maximize the potential for their agricultural products.    .",
    image:Finance
  },
  {
    title:"Finance",
    description:"Securing loans is a vital necessity for micro-small organisations, to address their financial needs and to enable them to invest in and improve their agricultural practices for sustained productivity.",
    image:loan
  },

  {
    title:"Eco-system",
    description:"The absence of a supportive community in agriculture hampers knowledge sharing. Combined with market access and certification issues, micro-small organisations face uncertainties, limiting profitability.",
    image:Ecosystem
  },
 

  {
    title:"Monetize Unutilized capacity ",
    description:" MSMEs often struggle to monetize their unutilized capacity, posing a significant challenge in optimizing resource efficiency and economic output. Finding innovative ways to leverage and capitalize on dormant assets remains a crucial hurdle for these businesses.",
    image:Technology
  },

  // {
  //   title:"Brand ",
  //   description:"Branding empowers agri-producers to establish unique identities for their products, enabling differentiation and market recognition. ",
  //   image:Brand
  // }

]
  return (
    <Container className="challenge-container subSectionWrapper">
      <h2 className="container-title mb-md-5 text-center">
        {" "}
    Agri-MSMEs Challenge
      </h2>

      <Row className="align-items-center justify-content-md-between ">
        <Col md={2}>
          <img src={Technology} alt="challange Image" className="mb-md-0 mb-4" />
        </Col>

        <Col md={9} className="px-md-0">
          <h2 className="container-heading2">Monetize Unutilized capacity</h2>
         {/* <p className="container-para">MSMEs often struggle to monetize their unutilized capacity, posing a significant challenge in optimizing resource efficiency and economic output. Finding innovative ways to leverage and capitalize on dormant assets remains a crucial hurdle for these businesses.</p> */}
<p className="container-para">
Micro, Small, and Medium Enterprises (MSMEs) frequently face challenges in monetizing their unutilized capacity, hindering optimal resource efficiency and economic output. Overcoming this obstacle necessitates the exploration of innovative strategies to leverage and capitalize on dormant assets, presenting a crucial avenue for these businesses to enhance their overall performance.</p>
          {/* <Row className="challangesRow justify-content-between ">
            {challanges.map((e)=>{
              return <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards  mb-md-4 ">
                <img src={e.image} alt="Challange Icon" className="mb-3" />
                <h6 className="mb-2">{e.title}</h6>
                <p className="mb-0">
                 {e.description}
                </p>
              </div>
            </Col>
           
            })}          
          </Row> */}
   
        </Col>
      </Row>
    </Container>
  );
}
