import React from "react";
import Icon4 from "../../images/gbrFarmerBenefits/increase.svg";
import Icon5 from "../../images/gbrFarmerBenefits/save-money.svg";
import Icon7 from "../../images/gbrFarmerBenefits/pay-per-click.svg";
import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function AgrientrepreneurBenefits() {
  const benefits = [
   
    {
      image: Icon4,
      title: "Improve Top line",
      bodycopy:
        "Experience exponential growth as sales revenue soars to new heights, unlocking unprecedented success for your business.",
    },
    {
      image: Icon5,
      title: "Access to Market ",
      bodycopy:
      "Access to local and global markets empowers processing entrepreneur with expanded opportunities for sales and economic growth."
    },
    // {
    //   image: Icon5,
    //   title: "Improved Pricing ",
    //   bodycopy:
    //   "Improved pricing helps entrepreneurs offer better value to customers while maximizing profits, boosting competitiveness and market share."
    // },
    // {
    //   image: Icon6,
    //   title: "Access to Machinery ",
    //   bodycopy:
    //     "Progressive farmers & micro small organisations benefit from facilitated access to machinery, enhancing operational efficiency and productivity."
    // },
    {
      image: Icon7,
      title: "Monetize unutilized processing capacity ",
      bodycopy:
        "Monetizing processing capacity allows processing entrepreneur to boost earnings by efficiently using their operational capabilities, unlocking additional revenue streams and improving overall profitability."
    },
    // {
    //   image: Icon8,
    //   title: "Access to farmers facilitation services ",
    //   bodycopy:
    //     "Experience the advantages of our seamless field support, including migration assistance, asset care, labor card facilitation, and buyback support. This comprehensive package ensures operational efficiency and provides peace of mind for farmers."
    // },
  ];
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title mt-4">Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </Container>

  );
}
