import React from "react";
import HomeSubSection from "../HomeSubSection/HomeSubSection";


export default function MeatAsset() {
  return (
    <>
   
      <div className="pt-4" id="Sheep">
        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="sheepImg"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale farmers & generate rural employment through Agri-MSMEs
      "
          descriptionTitle="Sheep"
        //   src={StorageMachImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
          onClick={() => {
            localStorage.setItem("abnUser", "AssetOEM");
          }}
        />
      </div>

      <div className="pt-4" id="MeatProcessingMachinery">
        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="ProcessingMachineImg"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale farmers & generate rural employment through Agri-MSMEs
      "
          descriptionTitle="Meat Processing Machinery"
        //   src={StorageMachImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
          onClick={() => {
            localStorage.setItem("abnUser", "AssetOEM");
          }}
        />
      </div>

   
    </>
  );
}
