import React from "react";

import Iaa3 from "../../images/IAA3.png";
import Iaa5 from "../../images/extractor.png"
import { Row, Col } from "react-bootstrap";
import "./IAABlock.css";
import {Button} from "@material-ui/core";

export default function IAABlock({ producers }) {
  const Image = [
    { img: Iaa3, href: "#Bee" },
    { img: Iaa5  },
    // { img: Iaa2, href:"#ProcessingMachinery" },
    // { img: Iaa4 },
  ];
  return (
    
    <>
      {!producers ? (
        <div className="Iaa-container Iaa-container--mod mb-5">
          <h2 className="text-center mb-0"></h2>

          <Row className="justify-content-center">
            {Image.map((e) => {
              return (
                <Col md={3} className="md-md-0 mb-3">
                  <a href={e.href}>
                    <div
                      className={`${
                        e.href
                          ? "imageHover Iaa-Cards d-flex justify-content-center align-items-center"
                          : "Iaa-Cards d-flex justify-content-center align-items-center card-deactivated"
                      }`}
                    >
                      {" "}
                      <img
                        className={`${e.href && "imgCursor filteredimg"}`}
                        src={e.img}
                        alt="icon"
                      />
                    </div>
                  </a>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : (
        <div className="Iaa-container Iaa-container--mod mb-5">
          <h2 className="text-center mb-0"> </h2>
          <Row className="justify-content-center">
            {Image.map((e) => {
              return (
                <Col md={3} className="md-md-0 mb-3">
                  
                    <div
                      // className={`${
                      //   e.href
                      //     ? "imageHover Iaa-Cards d-flex justify-content-center align-items-center"
                      //     : "Iaa-Cards d-flex justify-content-center align-items-center card-deactivated"
                      // }`}

                      className=" Iaa-Cards d-flex justify-content-center align-items-center"
                    >
                      {" "}
                      <img
                        // className={`${e.href && "imgCursor"}`}
                        src={e.img}
                        alt="icon"
                      />
                    </div>
                  
                </Col>
              );
            })}
          </Row>

          <Button variant="contained" color="primary">
          <a href="http://cbn.gbrapp.com/" target="_blank" rel="noopener noreferrer">Join </a>
        </Button>
        </div>
      )}
    </>
  );
}
