import React from "react";
import Certificate1 from "../../images/certificates/Halal.jpg";
import Certificate2 from "../../images/certificates/FSSC 22000.png";
import Certificate3 from "../../images/certificates/FSSAI.svg";
import Certificate4 from "../../images/certificates/GMP Good Manufacturing Practice certified logo vector-07.jpg";
import Certificate5 from "../../images/certificates/indian-organic.jpg";
import Certificate6 from "../../images/certificates/USDA organic logo- badge vector-03.jpg";
import Certificate7 from "../../images/certificates/iso_logo_01.jpg";
import { Row, Col } from "react-bootstrap";

export default function AgriEntreprenurCertifications() {
  const services = [
    // {
    //   title: "Community",
    //   image: Community,
    //   href: "/Community",
    // },
    
    
    {
      title: "FSSAI",
      image: Certificate3,
     
    },
    {
        title: "Indian Organic",
        image: Certificate5,
       
      },
      {
        title: "ISO ",
        image: Certificate7,
       
      },{
        title: "USDA ",
        image: Certificate6,
       
      },
      
      {
        title: "Food Safety System Certfication 22000",
        image: Certificate2,
        // customPropValue: "processing",
  
      },
      {
        title: "Good Manufacturing Practice",
        image: Certificate4,
       
      },
      {
        title: "Halal Certification",
        image: Certificate1,
        
      },
  ];

  return (
    <div className="container for-farmer-section2 subSectionWrapper px-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
      Certifications & Licenses
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => (
          <Col md={3} key={e.title} className="mb-4">
          
              <div className="image-container image-container--mod image-container-collections mb-4">
                <img className="" src={e.image} alt="Investment asset" />
              </div>
              <h3 className="text-center image-caption mt-0 mb-md-0 mb-4 text-decoration-none">
                {e.title}
              </h3>
          </Col>
        ))}
      </Row>
    </div>
  );
}
